import React from 'react'

// Third Party
import styled from 'styled-components'

interface EmptyspaceProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Emptyspace
}

const StyledEmptyspace = styled.div<{
  heightDesktop: any
  heightTablet: any
  heightMobile: any
}>`
  @media screen and (min-width: 992px) {
    height: ${({ heightDesktop }) => heightDesktop}px;
  }

  @media screen and (max-width: 991px) {
    height: ${({ heightTablet }) => heightTablet}px;
  }

  @media screen and (max-width: 575px) {
    height: ${({ heightMobile }) => heightMobile}px;
  }
`

const Emptyspace: React.FC<EmptyspaceProps> = ({ fields }) => (
  <section>
    <StyledEmptyspace
      heightDesktop={fields.heightgroup?.heightdesktop || 0}
      heightTablet={fields.heightgroup?.heighttablet || 0}
      heightMobile={fields.heightgroup?.heightmobile || 0}
    />
  </section>
)

export default Emptyspace
